/* jshint esversion: 9 */
/* jshint expr: true */

"use strict"
const {
    translator
} = require(`translator`)

export class Common {
    /**
     *
     * @param {string} label
     * @param {{}} params
     */
    static qTrans (label, params) {
        return translator.translate(document.documentElement.lang, label, {})
    }

    /**
     *
     * @param {string} routeName
     * @return {{meta: [{name: string, content: *}, {property: string, content: string}], title}}
     */
    static getMetaInfo (routeName) {
        return {
            title: this.qTrans(`${routeName}_title`, {}),
            meta: [
                { name: `description`, content: this.qTrans(`${routeName}_description`, {}) }
            ]
        }
    }
}
